import React, {useState, useRef, useEffect} from 'react';
import logo from '../RegioStore_Logo_Location.png';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, 
  useHistory,
  useLocation
} from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField } from '@material-ui/core';
import Keyboard from 'react-simple-keyboard';
import "react-simple-keyboard/build/css/index.css";
import "../index.css";
import {useActivity} from 'react-use-activity';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function PinView() {
  const classes = useStyles();
  const location = useLocation();
  const inputEl = useRef();
  const [rfid, setRfid] = useState("");
  const [pin, setPin] = useState("");
  const [pinLength, setPinLength] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [statusMsg, setStatusMsg] = useState("Bitte geben Sie den Pin ein");
  let history = useHistory();

  useActivity({
    timeout: 20000,
    onActivity: () => {},
    onInactivity: () => {history.push("/")},
    activityEvents: 'mousemove',
  });

  function inputOnChange(inputValue){
    if(inputValue.slice(-1) === "#"){
      history.push("/");
    }else{
      setPin(inputValue);
      if(pinLength === 3){
        setIsLoading(true);
        fetch(window._env_.RS_API_URL + '/customer/doorcontrol?rfid=' + rfid + '&pin=' + inputValue, {method : "post"})
        .then((resp)=>{ 
          const timer = setTimeout(() => {
            history.push("/");
          }, 5000);
          return resp.text() 
        })
        .then((text)=>{ setStatusMsg(text) })
        .finally(function(){
          setIsLoading(false);
        });
      }
      setPinLength(pinLength + 1);
    }
  }

  useEffect(() => {
    inputEl.current.focus();
    setRfid(location.state.rfid);
  }, [location]);

  return (
    <div className="container" >
      <Backdrop
          open={isLoading}
          className={classes.backdrop}
          >
          <CircularProgress color="inherit" />
          <Typography>Anfrage wird verarbeitet ...</Typography>
      </Backdrop>
      <header className="pinHeader">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <h2>
          {statusMsg}
        </h2>
        <TextField label="Pin" value={pin} variant="outlined" inputRef={inputEl} type="password" inputProps={{style: { textAlign: 'center', fontSize: 50 }}}/>
        <Keyboard
          theme={"hg-theme-default hg-layout-numeric numeric-theme"}
          //layoutName={this.state.layoutName}
          onChange={(input) => inputOnChange(input)}
          // onKeyPress={(button) => this.onKeyPress(button)}
          disableCaretPositioning={true}
          layout={{
            default: ["1 2 3", "4 5 6", "7 8 9", "# 0 {//}"],
          }}
          mergeDisplay={true}
          display={{
            "{bksp}": "Löschen",
            "#": "Zurück"
          }}
        />
      </header>
    </div> 
  );
}
