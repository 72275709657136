import logo from './RegioStore_Logo_Location.png';
import './App.css';
import BarcodeReader from 'react-barcode-reader';
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import RfidScan from './view/RfidScan.js';
import PinView from './view/PinView.js';

export default function App() {
  return (
    <Router>
        <Switch>
        <Route path="/pin">
            <PinView />
          </Route>
          <Route path="/">
            <RfidScan />
          </Route>
        </Switch>
    </Router>
  );
}