import React, {useState} from 'react';
import logo from '../RegioStore_Logo_Location.png';
import BarcodeReader from 'react-barcode-reader';
import {
    useHistory, useLocation
} from "react-router-dom";

function RfidScan() {
  const [rfid, setRfid] = useState("");
  const [statusMsg, setStatusMsg] = useState("Bitte scanne Deine Kundenkarte ...");
  const [supportMsg, setSupportMsg] = useState("Bei Fragen oder Problemen: 0157 517 796 79");
  let history = useHistory();

  function handleScan (data) {
    setRfid(data);
    history.push({
      pathname: '/pin',
      state: { rfid: data }
  });
  }

  function handleError (err) {
    console.error(err)
  }

  return (
    <div className="App">
      <BarcodeReader
          //testCode="0006765432"
          onError={handleError}
          onScan={handleScan}
          />
      <header className="pinHeader">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          {statusMsg}
        </h1>
        <h3>
          {supportMsg}
        </h3>
      </header>
    </div>
  );
  
}

export default RfidScan;
